System.register(["./disabledlink", "./lightbox", "./navigation", "./gdpr_consent", "./consent_pref_center"], function (exports_1, context_1) {
    "use strict";
    var __moduleName = context_1 && context_1.id;
    return {
        setters: [
            function (_1) {
            },
            function (_2) {
            },
            function (_3) {
            },
            function (_4) {
            },
            function (_5) {
            }
        ],
        execute: function () {
        }
    };
});
